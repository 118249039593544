<script setup lang="ts">
import { Field, ErrorMessage, useForm } from "vee-validate";
import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-Token"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

import { inject, ref } from "vue";

const props = defineProps({
    portal: {
        type: String,
        required: true,
    },
    form: {
        type: String,
        required: true,
    },
});

const { values, errors, meta, resetForm, setValues, setFieldValue } = useForm({
    keepValuesOnUnmount: true,
});
const currentStepIdx = ref(1);
const backStepIdx = ref(0);

const goNext = () => {
    backStepIdx.value = currentStepIdx.value;
    currentStepIdx.value++;
    window.lenis.scrollTo(document.getElementById(props.form), {
        offset: -160,
    });
    return;
};

const goBack = () => {
    if (currentStepIdx.value === 1) {
        return;
    }

    currentStepIdx.value = backStepIdx.value;
    backStepIdx.value--;
    window.lenis.scrollTo(document.getElementById(props.form), {
        offset: -160,
    });
};

const questions = [
    {
        id: 1,
        tagline: "Customer details",
        title: "What's your address?",
        description: null,
        fields: [
            {
                component: "InputControl",
                type: "text",
                name: "firstname",
                label: "First Name",
                placeholder: "First Name",
                rules: "required",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "text",
                name: "lastname",
                label: "Last Name",
                placeholder: "Last Name",
                rules: "required",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "email",
                name: "email",
                label: "Email",
                placeholder: "Email address",
                rules: "required|email",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "text",
                name: "phone",
                label: "Phone",
                placeholder: "Contact number",
                rules: "",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "text",
                name: "zip",
                label: "Postcode",
                placeholder: "Postcode/Eircode",
                rules: "required",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "text",
                name: "city",
                label: "City",
                placeholder: "City",
                rules: "required",
                class: "col-span-6 lg:col-span-3",
            },
            {
                component: "InputControl",
                type: "text",
                name: "address",
                label: "Address",
                placeholder: "Street address",
                rules: "required",
                class: "col-span-6",
            },
        ],
    },

    {
        id: 2,
        tagline: "Parking",
        title: "Do you have off-street parking?",
        description: null,
        fields: [
            {
                component: "RadioControl",
                name: "off_street_parking",
                rules: "required",
                class: "col-span-6",
                options: [
                    {
                        label: "Yes",
                        value: "Yes",
                        icon: "icon-yes",
                    },
                    {
                        label: "No",
                        value: "No",
                        icon: "icon-no",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        tagline: "Charger location",
        title: "Where would you like your charger?",
        description: null,
        fields: [
            {
                component: "RadioControl",
                name: "charger_location",
                rules: "required",
                class: "col-span-6",
                options: [
                    {
                        label: "Garage",
                        value: "Garage",
                        icon: "icon-house",
                    },
                    {
                        label: "House",
                        value: "House",
                        icon: "icon-house-terrace",
                    },
                    {
                        label: "Other",
                        value: "Other",
                        icon: "icon-house-new",
                    },
                ],
            },
        ],
    },

    {
        id: 4,
        tagline: "Cable",
        title: "How long does the cable need to be?",
        description:
            "This is the distance from Fuse Box / Meter to your chosen installation location i.e. Exterior Garage Wall. Standard Installation under the 10m.",
        fields: [
            {
                component: "RadioControl",
                name: "cable_length",
                rules: "required",
                class: "col-span-6",
                options: [
                    {
                        label: "0-10m",
                        value: "0-10",
                        icon: "icon-cable-lenght1",
                    },
                    {
                        label: "10-20m",
                        value: "10-20",
                        icon: "icon-cable-lenght2",
                    },
                    {
                        label: "20m+",
                        value: "20+",
                        icon: "icon-cable-lenght3",
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        tagline: "Chargers",
        title: "Select Charger",
        description: "",
        fields: [
            {
                component: "ChargerControl",
                name: "charger",
                rules: "required",
                class: "col-span-6",
                options: [
                    {
                        label: "Easee",
                        value: "Easee",
                        subline: "Minimalist Nordic Design",
                        image: "/uploads/easee.png",
                        content:
                            "<ul><li>Built-in: eSIM</li><li>Connectivity: WiFi 2.4 GHz b/g/n connection</li><li>Power output: 7.4kW / 32A</li><li>Type-2: socket</li><li>Supply voltage: 230 V</li><li>Dimensions: (H)256mm (W)193mm (D)106mm</li></ul>",
                    },
                    {
                        label: "Zappi",
                        value: "Zappi",
                        subline: "Great with solar, made in Ireland",
                        image: "/uploads/zappi-new.png",
                        content:
                            "<ul><li>Built-in: earthing</li><li>Connectivity: Ethernet &amp; Built-in Wifi</li><li>Power output: 7kW / 32A</li><li>Type-2: tethered 6.5m cable</li><li>Supply voltage: 230V AC Single Phase or 400V AC 3-phase (+/- 10%)</li><li>Dimensions: (H)439mm (W)282mm (D)122mm</li></ul>",
                    },
                    {
                        label: "Ohme",
                        value: "Ohme",
                        subline: "Features colour screen and tethered cable",

                        image: "/uploads/ohme.png",
                        content:
                            "<ul><li>Built-in: LCD screen</li><li>Connectivity: 3G/4G multi-net sim</li><li>Power output: 7.4kW / 32A</li><li>Type-2: tethered 5m cable</li><li>Supply voltage: 230V AC, 50HZ</li><li>Dimensions: (H)170mm (W)200mm (D)100mm</li></ul>",
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        tagline: "Installation",
        title: "Select a preferred date",
        description: null,
        fields: [
            {
                component: "DateControl",
                label: "Date",
                name: "preferred_date",
                placeholder: "Preferred Date",
                rules: "required",
                class: "col-span-6 lg:col-span-3",
            },
        ],
    },
];

const Dialog = inject("Dialog");
const loading = ref(false);

const Submit = () => {
    loading.value = true;

    setFieldValue("title", document.title);
    setFieldValue("page", window.location.href);
    setFieldValue("portal_id", props.portal);
    setFieldValue("form_id", props.form);

    axios
        .post("/api/home-charging-quote/send", values)
        .then((response) => {
            resetForm();

            if (response.data.redirect) {
                window.location = response.data.redirect;
            } else {
                Dialog.alert({
                    title: response.data.name,
                    message: response.data.message,
                });
            }
        })
        .catch((err) => {
            //  actions.setErrors(err.response.data.errors)
            if (err.response.data.redirect) {
                window.location = err.response.data.redirect;
            } else {
                Dialog.alert({
                    title: err.response.data.name,
                    message: err.response.data.message,
                });
            }
        })
        .then(() => {
            loading.value = false;
        });
};
</script>

<template>
    <div :id="form">
        <div class="w-full bg-white/5 h-1 rounded-lg overflow-hidden mb-8">
            <div
                class="bg-primary-500 h-1 transition-all duration-300"
                :style="{
                    width: `${((currentStepIdx - 1) / questions.length) * 100}%`,
                }"
            ></div>
        </div>

        <template v-for="(question, index) in questions" :key="index">
            <div v-if="currentStepIdx === question.id">
                <Transition
                    enter-from-class="opacity-0 translate-y-0"
                    enter-active-class="transition ease-in-out duration-300"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in-out duration-0"
                    leave-to-class="opacity-0 -translate-y-0"
                >
                    <div
                        v-show="currentStepIdx === question.id"
                        class="space-y-4"
                    >
                        <div
                            v-if="question.tagline"
                            class="uppercase text-sm font-medium tracking-wide text-primary-400"
                        >
                            {{ question.tagline }}
                        </div>
                        <div
                            v-if="question.title"
                            class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl"
                        >
                            {{ question.title }}
                        </div>
                        <div
                            v-if="question.description"
                            class="prose text-white/75 lg:prose-lg max-w-4xl"
                        >
                            {{ question.description }}
                        </div>

                        <renderer
                            v-if="question.fields.length"
                            v-model="values"
                            :fields="question.fields"
                        ></renderer>

                        <div class="flex gap-4">
                            <button
                                v-if="question.id > 1"
                                @click="goBack"
                                type="button"
                                class="border border-white/10 bg-white/5 py-2.5 px-6 font-medium text-sm disabled:opacity-50 disabled:cursor-not-allowed rounded"
                            >
                                Back
                            </button>

                            <button
                                v-if="question.id < questions.length"
                                :disabled="!meta.valid"
                                class="bg-primary-500 border border-primary-500 disabled:opacity-50 disabled:cursor-not-allowed rounded hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                type="button"
                                @click="goNext"
                            >
                                Continue
                            </button>

                            <button
                                v-if="question.id === questions.length"
                                :disabled="!meta.valid || loading"
                                @click="Submit"
                                class="relative bg-primary-500 border border-primary-500 disabled:opacity-50 disabled:cursor-not-allowed rounded hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                type="button"
                            >
                                <span :class="{ invisible: loading }"
                                    >Submit</span
                                >
                                <span
                                    v-if="loading"
                                    class="absolute inset-0 flex items-center justify-center"
                                >
                                    <svg
                                        class="animate-spin h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <HtmlContent
                            v-if="question.info"
                            :control="{ content: question.info }"
                        />
                    </div>
                </Transition>
            </div>
        </template>
    </div>
</template>
